// nuxt-ui/Shared/composables/images.ts

import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

const { isQueens } = useWebContext();

interface useImages {
  playerPlaceholderPath: string;
  videoBgTurnMvpPath: string;
  posterBgTurnMvpPath: string;
  videoBgFeaturedStatsPath: string;
  posterBgFeaturedStatsPath: string;
}

export function useImages(): useImages {
  const playerPlaceholderPath = isQueens
    ? "/img/placeholder/ql-player-placeholder.png"
    : "/img/placeholder/kl-player-placeholder.png";

  const videoBgTurnMvpPath = isQueens ? "/img/stats/bg-turn-mvp-QL.webm" : "/img/stats/bg-turn-mvp-KL.webm";

  const posterBgTurnMvpPath = isQueens
    ? "/img/stats/bg-turn-mvp-ql-fixed.jpg"
    : "/img/stats/bg-turn-mvp-kl-fixed.jpg";

  const videoBgFeaturedStatsPath = isQueens
    ? "/img/stats/bg-featured-stats-QL.webm"
    : "/img/stats/bg-featured-stats-KL.webm";

  const posterBgFeaturedStatsPath = isQueens
    ? "/img/stats/bg-stats-card-ql-fixed.jpg"
    : "/img/stats/bg-stats-card-kl-fixed.jpg";

  return {
    playerPlaceholderPath,
    videoBgTurnMvpPath,
    posterBgTurnMvpPath,
    videoBgFeaturedStatsPath,
    posterBgFeaturedStatsPath,
  };
}
